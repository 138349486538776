import React            from 'react'; 
import Newspaper        from '../components/Newspaper.jsx';
import Newspaper_4_col  from '../components/Newspaper_4_col.jsx';
import Paper            from '../components/Paper.jsx';
import styles           from './use_cases.module.css';
import {Context}        from '../services/context.js'

import pic_use_case_1 from '../pictures/Luxery shoes.webp'; 
import pic_use_case_2 from '../pictures/Palace Hotel San Francisco.webp'; 
import pic_use_case_3 from '../pictures/Airplane Maintenence.webp'; 
import pic_use_case_4 from '../pictures/Petty Cash.webp'; 
import { Helmet }           from 'react-helmet';


const text_1 = [
    <h6>Personal Applications</h6>,
    <p>
      The Back-Step Blockchain opens up two pivotal avenues for personal use. First, consider Trust.it, our publicly accessible blockchain. It's a common scenario: details 
      and receipts from past purchases, like that specific brand of circuit breakers you bought years ago, slip through the cracks of memory. With Trust.it, every 
      trivial detail can be securely logged on the blockchain, turning forgetfulness into a relic of the past. Whether it's tracking the purchase date of your Samsung 
      phone or storing recipes, the choice of privacy is yours—blocks can be encrypted for your eyes only or left in plain text for public sharing.
    </p>,

    <p>
      Speaking of sharing, my wife, an adept at blending Asian and Western culinary arts, uses Back-Step to share her recipes. The simplicity, speed, 
      and cost-efficiency of our blockchain technology break new ground, offering endless possibilities beyond traditional platforms.
    </p>,

    <p>
      The second category for personal application lies right in your handheld device. It's the foundation for digital currencies and a catalyst for developing 
      myriad exchange and payment solutions. Dive deeper into this under our "Trustful Base for Digital Currencies" section for insights on leveraging the 
      Back-Step Blockchain in your personal device for groundbreaking applications.
    </p>
  ];
  
/*
const text_2  = [ <h4>Small Business use cases</h4>,
 <p>Again, there are lots of applications if a small business runs its own Back-Step Blockchain or uses a public available service in encrypted mode. It starts from inventory 
    over accounting to staff records. Petty casch records can be stored on the blockchain. Supplier and customer data could be on the blockchain. 
    All kind of product data like manufactoring lots or version numbers could be on the blockchain. A company can decide which data from their chain the declare 
    public and which not.
</p>];
*/


const text_2 = [<h6>Small Business Blockchain Applications</h6>,
  <p>For small businesses, the adoption of a Back-Step Blockchain, whether through a proprietary system or a public service in encrypted form,
   unlocks a myriad of applications. This technology can revolutionize how businesses manage inventory, accounting, and employee records. 
   Blockchain allows for secure, immutable recording of petty cash transactions without outdated cash boxes.
   </p>,

   <p> 
   When was which product bought from wich supplier at what conditions. Blockchain does not forget. Supplier and customer information and detailed product data 
   such as manufacturing lots, version numbers related serial numbers need to be on the blockchain for future reference. Back-Step Blockchain empowers companies with 
  the flexibility to decide which data to share publicly and which to keep private, enhancing both transparency and confidentiality where needed.
  </p>,
  <img src={ pic_use_case_4} width='230' alt="Petty cash" />,];



const text_3 = [
  <h6>Companies with Valuable Products</h6>,

  <p><b>Combating Product Piracy</b></p>,

  <p>
    Among the myriad applications of the Back-Step Blockchain, its role in deterring product piracy stands out. Traditional anti-piracy measures often fall short 
    due to high costs and complex implementation. Back-Step Blockchain revolutionizes this space with its speed and affordability. At the product's creation, 
    a unique serial number is assigned and recorded on the blockchain. This initial block makes the item traceable through its journey from manufacturing to final 
    sale, with each transit point adding a new block detailing the product's movement.
  </p>,

  <p>
    Upon purchase, the product's sale is also registered, linking it to the buyer's warranty and including a certificate of origin with a unique verification code. 
    This enables consumers to easily verify their product's authenticity and journey from factory to their hands via the second code provided. Should a counterfeit 
    attempt to register with the same serial number, the system immediately flags it, capturing relevant data to aid in tracking and legal action.
  </p>,

  <p>
    This process not only deters piracy but also empowers smaller companies with valuable products to leverage blockchain technology affordably. 
    The Back-Step Blockchain's efficiency shines at the point of sale, where transaction speed is critical. Unlike traditional blockchains, where processing 
    can lag, Back-Step's swift block registration ensures a seamless customer experience.
  </p>,
  <img src={ pic_use_case_1} width='230' alt="Luxery_goods" />
];

  const text_4_1 = [
  <h6>Government, Organizations, and Beyond</h6>,
  <p>
    Discover the myriad applications of the Back-Step Blockchain that save time and instill trust. Join me on a journey to San Francisco in 2028, where I explore 
    its vast possibilities.
  </p>,
  <p>
    At the airport, an electronic immigration officer swiftly verified my identity through my new passport, integrated with the Back-Step-Blockchain. This system, 
    linked with Singapore's blockchain, double-checked my biometrics against both the passport's data and the airport's physical sensors. The Back-Step Blockchain 
    ensures the authenticity of my identity and travel history, speeding up my entry without the need for an interview.
  </p>,
  <p>
    Upon collecting my luggage, I proceeded to the car rental, where my driver's license was verified online via the Singapore police Back-Step Blockchain. 
    "No faked licenses anymore, thanks to Back-Step Blockchain," remarked the staff. This verification process, facilitated by my personal ID, underscores 
    the technology's role in simplifying everyday transactions—even as self-driving cars render traditional driving skills obsolete.
  </p>,
  <img src={ pic_use_case_2} width='230' alt="Hotel front desk" />,
  <p>
    At The Palace Hotel, the front desk seamlessly confirmed my reservation through their blockchain. A quick digital ID check, bridging my passport and 
    the hotel's systems—both powered by Back-Step—ensured a swift check-in. The hotel even offered to add its data to my passport blockchain, a 
    testament to Back-Step's flexibility in managing personal travel records and preferences.
  </p>,
  <p>
    My hotel bill was accessible on their blockchain, allowing me to transfer it to my private blockchain for expense tracking. This integration highlights 
    Back-Step's utility in managing financial records, further exemplified by my use of the Back-Step Wallet for seamless currency management.
  </p>,
  <p>
    Registering a subsidiary in the US was remarkably streamlined. By uploading directorial resolutions to my company's blockchain and interacting with 
    various legal entities through their respective blockchains, I experienced a frictionless and efficient process, unencumbered by traditional bureaucratic hurdles.
  </p>,
  <p>
    Verifying job candidates' education through university blockchains and discerning authentic news via media blockchains underscored Back-Step's role in ensuring 
    credibility. This technology offers a robust method for authenticating information in an era of misinformation.
  </p>,
    <img src={ pic_use_case_3} width='230' alt="Airplane maintenance" />,
  <p>
    As I concluded my journey with a flight back to Singapore, I was reassured knowing the aviation industry's reliance on Back-Step Blockchain for maintaining 
    licensing, training, and maintenance records. This implementation not only enhances safety protocols but also ensures transparency and trustworthiness in critical data.
  </p>,
  <p>
    Reflecting on my futuristic voyage, it's clear the Back-Step Blockchain's applications are only limited by our imagination. Its potential to revolutionize 
    industries, ensure data integrity, and streamline processes is just beginning to be uncovered.
  </p>,
];



export class UseCases extends React.Component  
{

    //------------------------------------------------------------------
    //  Constructor
    //------------------------------------------------------------------
    constructor(props) 
    {
        super(props);

    }

    //------------------------------------------------
    //  Component Mount
    //------------------------------------------------
    componentDidMount()
    {
        this.forceUpdate();
        window.scrollTo({top:0, left: 0, behavious: 'smooth'});
    }



    //--------------------------------------------------------------------------
    // Prepare for rendering  -- Portrait --
    //--------------------------------------------------------------------------
    render()
    { 
      if (Context.portrait)
        {
          this.component = 

          <div className={styles.backdrop_portrait}>

              <Helmet>
              <title> Back-Step Blockchain Use Cases </title>
              <meta name="description" content="Show a few use cases. Many are not possible with traditional blockchains. Imagine, you have your wn blockchain in your phone." />
              <meta charset="utf-8" />
              </Helmet>

              <div className={styles.grid_header_portrait}>
                  Back-Step Use Cases
              </div>

              <div className={styles.grid_item_portrait}> 
                  < Paper textLines = {text_1}/> 
              </div>

              <div className={styles.grid_item_portrait}>
                  < Paper  textLines = {text_2}/>
              </div>
              
              <div className={styles.grid_item_portrait}>
                  <Paper  textLines = {text_3}/>
              </div>

              <div className={styles.grid_item_portrait}>
                  <Paper  textLines = {text_4_1}/>
              </div>

          </div>

        }
        else
        {
        //--------------------------------------------------------------------------
        // Prepare for rendering  -- Landscape --
        //--------------------------------------------------------------------------
          this.component = 
              <div className={styles.backdrop}>

                <Helmet>
                <title>Use cases for Back-Step Blockchain</title>
                <meta name="description" content="Show a few use cases. Many are not possible with traditional blockchains. Imagine, you have your wn blockchain in your phone." />
                <meta charset="utf-8" />
                </Helmet>

                <div className={styles.grid_container}>
                
                  <div className={styles.grid_item}> 
                  < Newspaper textLines = {text_1}/> 
                  </div>

                  <div className={styles.grid_item}>
                      <Newspaper  textLines = {text_2}/>
                  </div>
                  
                  <div className={styles.grid_item}>
                      <Newspaper  textLines = {text_3}/>
                  </div>

                  <div className={styles.grid_item}>
                      <Newspaper  textLines = {text_4_1}/>
                  </div>

                </div>
              </div>
        }
    
        return this.component;
    }

}