//------------ For landscape only -------------

import React, { useState, useEffect, useRef } from 'react'; 
import Container        from 'react-bootstrap/Container';

import {What_is_BSB}    from './what_is_BSB.jsx';
import {UseCases}       from './use_cases.jsx';
import {Money}          from './money.jsx';
import {HowItWorks}     from './how_it_works.jsx';
import {PromptSecurity} from './prompt_security.jsx';
import {useLocation}    from 'react-router-dom';
import styles           from './overview.module.css';
import {Helmet}         from 'react-helmet';


import MyCard     from '../components/MyCardV2';

import pic_home_1 from '../pictures/Blockchain box with people.png';
import pic_home_2 from '../pictures/what_can_it_do.jpg';
import pic_home_4 from '../pictures/Currency.webp'; 
import pic_home_3 from '../pictures/University.webp';
import pic_home_5 from '../pictures/AI agent bought handbag.webp';
import pic_home_6 from '../pictures/API.webp';

// import { Alert } from "react-bootstrap";

const images =[
  {title:'Pic 1', picture: pic_home_1, index:1},
  {title:'Pic 2', picture: pic_home_2, index:2},
  {title:'Pic 3', picture: pic_home_3, index:3}, 
  {title:'Pic 4', picture: pic_home_4, index:4},
  {title:'Pic 5', picture: pic_home_5, index:5},
  {title:'Pic 6', picture: pic_home_6, index:6}] ;
 
const title_home_1 = `What is Back-Step Blockchain?`;
const text_home_1  = `Back-Step Blockchain is a new chain technology for existing and new blockchain applications. It has many advantages that it will dominate the blockchain market.`     

const title_home_2 = "Thousand+++ use cases";
const text_home_2  = `From private life, via AI models, to small and multinational businesses. Don't forget governments and plenty of technical use cases.`

const title_home_3 ="Back-Step Blockchain - How it works";
const text_home_3  =`We explain how it works in an overview for people with blockchain background. `;

const title_home_4 ="Trustful Digital Currencies.";
const text_home_4  =`Back-Step Blockchain opens the door for a new class of cryptocurrencies. Trustfull, stable and fast. Exactly what you need for your daily life.`;


const title_home_5 = "Back-Step Blockchain for AI-Security";
const text_home_5  = [<p>Artificial Intelligence inspired some bad guys to use prompt attacks. See how 
Back-Step Blockchain Proxy helps.
</p>];

const title_home_6 = "API to create your own app.";
const text_home_6  = [<p>You know your customers better than enybody else. Create apps for them they couldn't belive they were possible. 
We help you learn the Back-Step Blockchain and then you change the market!
</p>, 
  
<p>
More details follow soon...</p>];


//-----------------------------------------------------
//  Function code converted from class code
//-----------------------------------------------------
export const Overview = (props) => 
{
  const location = useLocation();

  // Replicating componentDidUpdate for location.state
  const prevLocationState = useRef(location.state);
  
  useEffect(() => 
  {
    if (prevLocationState.current !== location.state) 
    {
      if (selectedForm > 0)
      {
        setSelectedForm(0)
      }
      prevLocationState.current = location.state;
    }
  }, [location.state]);


  // Selected form is initialized from props.form (if provided), otherwise null (grid view)

  const [selectedForm, setSelectedForm] = useState(props.form || 0);

  // This function is equivalent to the detail_card method in your class.
  
  const detailCard = (select) =>
  {  
    setSelectedForm(select)
  }  
  const mainForm = (

      <div className={styles.backdrop}>    

        <Helmet>
          <title>Overview showing many aspects of the invention</title>
          <meta name="description" content="Short description of the details explained in own pages." />
          <meta charset="utf-8" />
        </Helmet>

        <Container className={styles.grid_container}>

          <MyCard 
            title={title_home_1}
            img={pic_home_1} 
            text={text_home_1}
            btn="See more..."
            detail={()=>detailCard(1)}>
          </MyCard> 

          <MyCard 
            title={title_home_2}
            img={pic_home_2} 
            text={text_home_2}
            btn="See more..."
            detail={()=>detailCard(2)}>

          </MyCard>  
        </Container>

        <Container className={styles.grid_container}>                   
          <MyCard 
            title={title_home_3}
            img={pic_home_3} 
            text={text_home_3}
            btn="See more..."
            detail={()=>detailCard(3)}>            
          </MyCard>  

          <MyCard 
            title={title_home_4}
            img={pic_home_4} 
            text={text_home_4}
            btn="See more..."
            detail={()=> detailCard(4)}>  
          </MyCard>  
        </Container>

        <Container className={styles.grid_container}>                   
          <MyCard 
            title={title_home_5}
            img={pic_home_5} 
            text={text_home_5}
            btn="See more..."
            detail={()=>detailCard(5)}>            
          </MyCard>  

          <MyCard 
            title={title_home_6}
            img={pic_home_6} 
            text={text_home_6}
            btn="See more..."
            detail={()=>detailCard(6)}>            
          </MyCard>  
      </Container>       
    </div>
  )
  let componentToRender;

  switch (selectedForm) 
  {
    case 1:
      componentToRender = <div><What_is_BSB/></div>;
      break;

      case 2:  
      componentToRender = <div><UseCases/></div>;
      break;

      case 3:  
      componentToRender = <div><HowItWorks/></div>;
      break;

      case 4:  
      componentToRender = <div><Money/></div>;
      break;

      case 5:  
      componentToRender = <div><PromptSecurity/></div>
      break;

      case 6:  
      componentToRender = mainForm;
      break;

    default:
      componentToRender = mainForm;
  }
  return componentToRender;
}
  
