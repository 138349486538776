
import React, { useState, useEffect, useRef } from 'react'; 
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import styles             from './products.module.css';
import {Context}          from '../services/context.js';
import MyCard             from '../components/MyCardV2.js';
import MyCardPortrait     from '../components/MyCardPortrait.js';
import {BSB_B010}         from './bsb_b010.jsx'
import {TrustIt}          from './trust_it.jsx';
import {BSB300}           from './bsb_300.jsx'
import {BSB700}           from './bsb_700.jsx';

import pic_product_1 from '../pictures/Sample.png';               // trust it
import pic_product_2 from  '../pictures/PCI_express_front.png';   // Server board
import pic_product_3 from '../pictures/BSB 300.png'; 
import pic_product_4 from '../pictures/003.jpg';

import { Helmet }           from 'react-helmet';

const title_product_1 = <p> Trust.it - Your WEB app </p>;
const text_product_1  = [<p>Soon avilable on-line.
Trust.it is a public application on the Back-Step Blockchain. It keeps your data forever. It is available on your browser, first as desktop app and then a bit later for mobile devices.
</p>];

const title_product_2 = <p>Back-Step Server Board</p>;
const text_product_2  = [ <p>For businesses and service providers with their own Back-Step Server. </p>];

const title_product_3 = <p>BSB Business Version</p>;
const text_product_3  = [`For all bussiness applications, also ideal to replace and expand existing Cryptocurrency blockchains.`]

const title_product_4 = <p>AI Currency Processor</p>;
const text_product_4  = [`In prototype state. High speed block processing for challenging applications like real time Credit Card transactions during peak times or AI transactions. 
The product realized a pass through architecture with seperate processors for raw block input and for processed blocks ready for storage.`]



//-----------------------------------------------------
//  Function code converted from class code
//-----------------------------------------------------
const Products = (props) => 
{
    const location = useLocation();
  
    const prevLocationState = useRef(location.state);

    //-----------------------------------------------------------    
    //
    //-----------------------------------------------------------    
    useEffect(() => 
    {
      if (prevLocationState.current !== location.state) 
      {
        if (selectedForm > 0)
        {
          setSelectedForm(0)
        }
        prevLocationState.current = location.state;
      }
    }, [location.state]);
 
    const [selectedForm, setSelectedForm] = useState(0);
  
    //-----------------------------------------------------------    
    //
    //-----------------------------------------------------------    
    const detailCard = (select) =>
    {  
      setSelectedForm(select)
    }  

    let mainForm = ''
    let componentToRender ='';
    
    if (Context.portrait)
    {
      mainForm = (

        <div className={styles.portrait_backdrop}>

          <div className={styles.portrait_container}>

            <Helmet>
              <title>Overview showing many aspects of the invention</title>
              <meta name="description" content="Short description of the details explained in own pages." />
              <meta charSet="utf-8" />
            </Helmet>
    

            <div className={styles.portrait_header}>Products</div>
    
            <div className={styles.portrait_item}>
              <MyCardPortrait 
                title={title_product_1}
                img={pic_product_1} 
                text={text_product_1}
                btn="See more..."
                detail={() => detailCard(1)}
              />
            </div>
    
            <div className={styles.portrait_item}>
              <MyCardPortrait 
                title={title_product_2}
                img={pic_product_2} 
                text={text_product_2}
                btn="See more..."
                detail={() => detailCard(2)}
              />
            </div>
    
            <div className={styles.portrait_item}>
              <MyCardPortrait 
                title={title_product_3}
                img={pic_product_3} 
                text={text_product_3}
                btn="See more..."
                detail={() => detailCard(3)}
              />
            </div>
    
            <div className={styles.portrait_item}>
              <MyCardPortrait 
                title={title_product_4}
                img={pic_product_4} 
                text={text_product_4}
                btn="See more..."
                detail={() => detailCard(4)}
              />
            </div>
          </div>
        </div>
      );

      switch (selectedForm) 
      {

          case 1:  
          componentToRender = <div><TrustIt/></div>;
          break;

          case 2:  
          componentToRender = <div><BSB_B010/></div>;  // HW PCIe board
          break;

          case 3:  
          componentToRender = <div><BSB300/></div>;
          break;

          case 4:  
          componentToRender = <div><BSB700/></div>;
          break;

          default:
          componentToRender = mainForm;
          break;
      }


    return componentToRender;

  }
  else
  {
    mainForm = (
    <div className={styles.backdrop}>
       <Helmet>
        <title>Product Overview</title>
        <meta name="description" content="Shows products available and under development. Each product has its own page" />
        <meta charset="utf-8" />
      </Helmet>

      <div className={styles.grid_container}>

        <div className={styles.grid_item}>
          <MyCard 
          key ={5}
            title={title_product_1}
            img={pic_product_1} 
            text={text_product_1}
            btn="See more..."
            detail={()=>detailCard(1)}>
          </MyCard>  
        </div>

        <div className={styles.grid_item}> 
          <MyCard 
            key ={6}
            title={title_product_2}
            img={pic_product_2} 
            text={text_product_2}
            btn="Read more..."
            detail={()=>detailCard(2)}>            
          </MyCard>  
        </div>

        <div className={styles.grid_item}> 
          <MyCard 
          key ={7}
            title={title_product_3}
            img={pic_product_3} 
            text={text_product_3}
            btn="Read more..."
            detail={()=>detailCard(3)}>  
          </MyCard>  
        </div>

        <div className={styles.grid_item}> 
          <MyCard 
          key ={8}
            title={title_product_4}
            img={pic_product_4} 
            text={text_product_4}
            btn="Read more..."
            detail={()=>detailCard(4)}>            
          </MyCard>  
        </div>
      </div>
    </div>
  )}


  switch (selectedForm)
  {

    case 1:  
    componentToRender = <div><TrustIt/></div>;
    break;

    case 2:  
    componentToRender = <div><BSB_B010/></div>;  // HW PCIe board
    break;

    case 3:  
    componentToRender = <div><BSB300/></div>;
    break;

    case 4:  
    componentToRender = <div><BSB700/></div>;
    break;

    default:
      componentToRender = mainForm;
  }
  return  componentToRender ;
}

export default Products;


  

/*
//------------------------------------------------
//  Class
//------------------------------------------------
export class Products extends React.Component  
{

  //------------------------------------------------
  //  Constructor
  //------------------------------------------------
  constructor(props) 
  {    
    super(props);
    this.mainform           = ''
    this.selected_form      = props.form;
    this.detail_card        = this.detail_card.bind(this);
   }

  //------------------------------------------------
  //  Component Mount
  //------------------------------------------------
  componentDidMount(props)
  {
    console.log('Cmponent did mount in product', this.props);
      this.prepare_for_rendering();
      this.forceUpdate();
  }

  //------------------------------------------------ 
  //
  //------------------------------------------------
  componentDidUpdate(prevProps) 
  {
    if (prevProps.version !== this.props.version) 
    {
  
      // You can do something here when version changes
    }
  }

  //-----------------------------------------------
  //  Methods to show the full details of a topic
  //----------------------------------------------- 
  detail_card(select)
   {
    console.log('detail_card', select);

    this.selected_form = select;
    //this.prepare_for_rendering();
    this.forceUpdate();
   }
   

  //------------------------------------------------------
  //  Return the render data
  //------------------------------------------------------
  prepare_for_rendering()
  {
    if (Context.portrait)
    {
        console.log('Portrait products');

        this.mainform = 
        <div>
           <Helmet> 
            <title>Product Overview</title>
            <meta name="description" content="Shows products available and under development. Each product has its own page" />
            <meta charset="utf-8" />
          </Helmet>

          <div className={styles.backdrop_portrait}>

            <div className={styles.grid_item}>
                <MyCardPortrait 
                  key ={1}
                  title={title_product_1}
                  img={pic_product_1} 
                  text={text_product_1}
                  btn="See more..."
                  detail={()=>this.detail_card(1)}>
                </MyCardPortrait>  
              </div>

              <div className={styles.grid_item}> 
                <MyCardPortrait 
                  key ={2}
                  title={title_product_2}
                  img={pic_product_2} 
                  text={text_product_2}
                  btn="Read more..."
                  detail={()=>this.detail_card(2)}>            
                </MyCardPortrait>  
              </div>

              <div className={styles.grid_item}> 
                <MyCardPortrait 
                  key ={3} 
                  title={title_product_3}
                  img={pic_product_3} 
                  text={text_product_3}
                  btn="Read more..."
                  detail={()=>this.detail_card(3)}>  
                </MyCardPortrait>  
              </div>

              <div className={styles.grid_item}> 
                <MyCardPortrait 
                  key ={4}
                  title={title_product_4}
                  img={pic_product_4} 
                  text={text_product_4}
                  btn="Read more..."
                  detail={()=>this.detail_card(4)}>            
                </MyCardPortrait>  
              </div>
            </div>
            </div>
    }
    else
    {

        this.mainform = 
        <div className={styles.backdrop}>
           <Helmet>
            <title>Product Overview</title>
            <meta name="description" content="Shows products available and under development. Each product has its own page" />
            <meta charset="utf-8" />
          </Helmet>

          <div className={styles.grid_container}>

            <div className={styles.grid_item}>
              <MyCard 
              key ={5}
                title={title_product_1}
                img={pic_product_1} 
                text={text_product_1}
                btn="See more..."
                detail={()=>this.detail_card(1)}>
              </MyCard>  
            </div>

            <div className={styles.grid_item}> 
              <MyCard 
                key ={6}
                title={title_product_2}
                img={pic_product_2} 
                text={text_product_2}
                btn="Read more..."
                detail={()=>this.detail_card(2)}>            
              </MyCard>  
            </div>

            <div className={styles.grid_item}> 
              <MyCard 
              key ={7}
                title={title_product_3}
                img={pic_product_3} 
                text={text_product_3}
                btn="Read more..."
                detail={()=>this.detail_card(3)}>  
              </MyCard>  
            </div>

            <div className={styles.grid_item}> 
              <MyCard 
              key ={8}
                title={title_product_4}
                img={pic_product_4} 
                text={text_product_4}
                btn="Read more..."
                detail={()=>this.detail_card(4)}>            
              </MyCard>  
            </div>
          </div>
        </div>
  }
}
 
  render() 
  {
    switch (this.selected_form)
    {
 
        case 1:  
        this.component = <div><TrustIt/></div>;
        break;

        case 2:  
        this.component = <div><BSB_B010/></div>;  // HW PCIe board
        break;

        case 3:  
        this.component = <div><BSB300/></div>;
        break;

        case 4:  
        this.component = <div><BSB700/></div>;
        break;

        default:
        this.component = this.mainform;
    }


    this.selected_form = this.mainform;
    return  this.component ;
  }
}
*/