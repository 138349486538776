import React     from 'react'; 

import Newspaper  from '../components/Newspaper.jsx';
import Newspaper_4_col     from '../components/Newspaper_4_col.jsx';
import Paper      from '../components/Paper.jsx';
import styles     from './publications.module.css';
import {Context}  from '../services/context.js'
import { Helmet } from 'react-helmet';
import PDFViewer  from '../components/pdf_viewer.jsx';
import press_release_1 from "../pictures/PressRelease1.pdf"
import white_paper_ai  from "../pictures/White Paper Prompt security.pdf"

    export class Publications extends React.Component  
    { 

      //------------------------------------------------------------------
      //  Constructor
      //------------------------------------------------------------------
      constructor(props) 
      {
        super(props);
        this.component= "";
      }  
  
      componentDidMount()
      {
        // console.log('Component did mount in About Us');
        this.forceUpdate();
        window.scrollTo({top:0, left: 0, behavious: 'smooth'});
      };
      
      // className={styles.backdrop}
      //---------------------------------------------------------------------------
      //  Prepare for portrait devices first
      //---------------------------------------------------------------------------
      render()
      { 
        if (Context.portrait)
        {
            this.component = 
                <div className={styles.backdrop_portrait}>
                    <Helmet>
                      <title>Back-Step Blockchain Publications</title>
                      <meta name="description" content="Related articles and white papers published by Blockchain Systems Pte. Ltd." />
                      <meta charset="utf-8" />
                    </Helmet>

   
                      <div className={styles.grid_header_portrait}>
                      Recent White Paper

                      <PDFViewer file={white_paper_ai}/>
                      </div>
{/*
                      <div className={styles.grid_item}> 
                        <PDFViewer file='../pictures/White Paper Prompt security.pdf' />
                      </div>
*/}                    


                </div>
        }
        else
        //---------------------------------------------------------------------------
        //  Prepare for Landscape devices herefirst
        //---------------------------------------------------------------------------
        {
          this.component = 
          <div className={styles.backdrop}>
  
            <Helmet>
                <title>What we published</title>
                <meta name="description" content="Publications about Back-Step Blockchain" />
                <meta charset="utf-8" />
            </Helmet>

              <div className={styles.grid_container}>
  
                <div className={styles.grid_header}>
                <h4>Recent White Paper</h4>
                </div>

     
                <div className={styles.grid_item}> 
                  <iframe
                    src= {white_paper_ai}
                    width="100%"
                    height="1000px"
                  />
                </div>

                <div className={styles.grid_item}> 
                <iframe
                    src= {press_release_1}
                    width="100%"
                    height="1000px"
                />
                </div>

              </div>
        </div>

        }
        return this.component ;
      }
  
}
/*
<iframe
src="../pictures/Press Release 1.pdf"
width="100%"
height="500px"
/>
*/
