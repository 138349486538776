
import React, { Component } from 'react';
import styles       from './contact_form.module.css';
import { Buffer }   from 'buffer';
import { Context }  from '../services/context';
import pic_bird     from '../pictures/Letterbird.png';
import { Helmet }   from 'react-helmet';

  //------------------------------------------------------------
  //
  //------------------------------------------------------------
class ContactForm extends Component 
{
    constructor(props) 
    {
        super(props);
        this.state = 
        {
        message: '',
        formSubmitted: false, // New state to track form submission
        isSubmitButtonDisabled: true, // New state to control the submit button's disabled status
        emailFailed: false
        };
        console.log('contact form constructor');
    }

    componentDidMount() 
    {
        this.portrait = Context.portrait;
        this.forceUpdate();
    }



    //------------------------------------------------------------------
    //  Prepare the portrait output
    //------------------------------------------------------------------
    render()
    { 
      if (Context.portrait)
      {

        this.component = 
          <div className={styles.portrait_backdrop}>

            <Helmet>
            <title>Contact to Blockchain Systems</title>
            <meta name="description" content="Shows that people with Trust.it registration can use the chain to communicate to Blockchain Systems" />
            <meta charset="utf-8" />
            </Helmet>

            <div className={styles.portrait_header}>
              We like your feedback!
            </div>
    
            <div className={styles.portrait_item}> Priority access for Trust.it registered user when you use the app to contact us.  
              <br/><br/>
              Otherwise mail us at feedback@backstepchain.com 
            </div>
    
            <div  className= {styles.portrait_image}>
              <img src = {pic_bird} alt="Letterbird in contact page" />
              <p><small>Our beautifull bird knows the way to us. Don't forget the stamp. This is to feed the bird.</small></p>
            </div>

          </div>
      }
      else
      {
          //------------------------------------------------------------
          //  Prepare landscape output
          //------------------------------------------------------------
          console.log('Contact landscape');
          
          this.component = 
          <div className={styles.backdrop}>

            <Helmet>
            <title>Contact to Blockchain Systems</title>
            <meta name="description" content="Shows that people with Trust.it registration can use the chain to communicate to Blockchain Systems" />
            <meta charset="utf-8" />
            </Helmet>

            <div className={styles.grid_container}>
              <div className={styles.text}> 
                <b>Priority access for Trust.it registered user. 
                <br/>Please use the app to contact us.  
                <br/>
                Otherwise mail us at feedback@backstepchain.com
                </b> 
            </div>

            <div className = {styles.grid_image}>
               <img src = {pic_bird} alt="Letterbird in contact page" className={styles.bird} />
            </div>
            <div className={styles.text}>
               <p><b>Our beautifull Chirp messaging bird knows the way to us. Don't forget the stamp to feed the bird.</b></p>
            </div> 
          </div>
        </div>
      }
 
      return this.component;
    }
}

export default ContactForm;
