// import {useEffect} from 'react';


export default function Footer()
{
  const MoveTop = () =>
  {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }

  return (
    <div style={{ position: 'fixed', bottom: '0px', left: '0px', width:'100%', fontSize: '0.4rem', backgroundColor: 'rgb(211,211,211)', zIndex: '1000'}} onClick={MoveTop}>
        (c) Copyright Georg Krause, 2023 - Impressum at About 
        <span> <strong>- Click to Top</strong></span>
    </div>
  )
}

