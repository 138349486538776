// CustomButton.jsx

import React, { useState, useRef } from 'react';
//import clickSound from '../sound/Mouse-Click.wav';     // Adjust the path as necessary
import styles from './custom_button.module.css';                            // We'll create this CSS file next


const CustomButton = (
{
    children,
    onClick,
    enabled,
    selected,
}) => 
{
        const [isClicked, setIsClicked] = useState(false);
        //const audioRef = useRef(new Audio(clickSound));

        if (! enabled) {selected = false}


        const handleClick = (event) => 
        {
            if (! enabled) return;

            // Play the click sound

          //  audioRef.current.currentTime = 0; // Rewind to start in case it's already playing
          //  audioRef.current.play();

            // Trigger the visual effect
 
            setIsClicked(true);
            setTimeout(() => setIsClicked(false), 200); // Duration of the effect

            // Call the original onClick handler
            if (onClick) 
            {
                onClick(event);
            }
        };

  // Combine existing class names with the 'clicked' class
  //const buttonClass = isClicked ? '{className = styles.clicked}' : '';

  return (
    <div>
      <button 
        className={`${styles.my_button} ${selected ? styles.selected : ''} ${ enabled ? '' : styles.disabled}  ${ isClicked ? styles.clicked : '' }`}
        type = 'button'
        onClick={handleClick}
        > {children} 
      </button>
 
    </div>
  );
};

export default CustomButton;
