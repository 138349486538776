import React, { Component } from 'react';
import styles from '../components/Newspaper.module.css';



class Newspaper extends Component 
{
  renderElement(element, index) 
  {
    //----- Direct image is an object with a 'src' property -----
    if (typeof element === 'object' && element.src) 
    {
      return <img key={index} src={element.src} width={element.width} alt={element.alt || 'Image'} className={styles.image} />;
    }

    //----- Shadow image header -----
    if (typeof element === 'object' && element.shadow)
    {
      return <p className={styles.image_shadow}> {element.shadow} </p>
    } 


    //----- Text header -----
    if (typeof element === 'object' && element.header)
    {
      return <p key={index} style={{fontSize: '0.8rem', fontWeight: '400', textAlign: 'center' }}>{element.header}</p>;      
    } 

    //----- Element is not an object, then we assume just text
    return <p key={index} >{element}</p>;  
  }
  



/*


    // Assuming that an image element is an object with a 'src' property
    if (typeof element === 'object' && element.src) 
    {
      //console.log('image detetcted');
      return <img key={index} src={element.src} width={element.width} alt={element.alt || 'Image'} className={styles.image} />;
    }

    // Otherwise, treat it as text
    return <p key={index} className={styles.p}>{element}</p>;
  }
*/

  render() 
  {
    const { textLines } = this.props;

    return (
      <div className={styles.text_block}>
        <div className={styles.multi_column_2}>
          {textLines.map((line, index) => this.renderElement(line, index))}
        </div>
      </div>
    );
  }
}

export default Newspaper;
