import React            from 'react'; 
import Newspaper        from '../components/Newspaper.jsx';
import Paper            from '../components/Paper.jsx';
import styles           from './prompt_security.module.css';
import {Context}        from '../services/context.js'

import pic_use_case_1   from '../pictures/Luxery shoes.webp'; 
import { Helmet }           from 'react-helmet';

const text_1 = [
    {header: 'Prompt Proxy for AI apps'},
    <p>
    Users specify what they want from the AI system. In its simplest form, users interact with a traditional LLM like ChatGPT in a text-based dialogue. 
    However, attackers might modify the prompt text before it is sent to the AI server, leaving users unaware of the actual request made to the AI model; a serious concern.
    The problem becomes even more critical when agents act on your behalf. Did the agent do what you wanted, or did he follow hidden instructions. 
    In these cases, the Back-Step Blockchain can serve as both a proxy and a logbook.
    For more details on how this works, please refer to the white paper "Prompt Security Proxy Based on Back-Step Blockchain" in the <b>Publications page</b>.
  </p>,

  { shadow: <img src={pic_use_case_1} width="230" />},
  <p>Who ordered this?</p>
];

 //<img src={ pic_use_case_1} width='230' alt="Luxery_goods" />


export class PromptSecurity extends React.Component  
{

    //------------------------------------------------------------------
    //  Constructor
    //------------------------------------------------------------------
    constructor(props) 
    {
        super(props);

    }

    //------------------------------------------------
    //  Component Mount
    //------------------------------------------------
    componentDidMount()
    {
        this.forceUpdate();
        window.scrollTo({top:0, left: 0, behavious: 'smooth'});

    }



    //--------------------------------------------------------------------------
    // Prepare for rendering  -- Portrait --
    //--------------------------------------------------------------------------
   render()
    { 
      if (Context.portrait)
        {

          this.component = 
            <div className={styles.portrait_backdrop}>
    
            <Helmet>
              <title>AI Security with Back-Step Blockchain</title>
              <meta name="description" content="Explains Back-Step Blockchain for AI." />
              <meta charset="utf-8" />
            </Helmet>
          
            <div className={styles.portrait_header}>
               Back-Step for AI
            </div>              

            <div className={styles.portrait_item}> 
              < Paper textLines = {text_1}/> 
              
            </div>
          </div>
        }
        else
        {
        //--------------------------------------------------------------------------
        // Prepare for rendering  -- Landscape --
        //--------------------------------------------------------------------------
          this.component = 

              <div className={styles.backdrop}>

                <Helmet>
                <title>Prompt security for AI</title>
                <meta name="description" content="Reference to a white paper" />
                <meta charset="utf-8" />
                </Helmet>

                <div className={styles.grid_container}>

                  <div className={styles.grid_item}> 
                  < Paper textLines = {text_1}/> 
                  </div>
                </div>
              </div>
        }
 
        return this.component;
    }

}