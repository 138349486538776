import React          from 'react'; 
import styles         from './trust_it.module.css';
import Newspaper      from '../components/Newspaper.jsx';
import Paper          from '../components/Paper.jsx';
import {Context}      from '../services/context.js';

import pic_trust_it_1 from '../pictures/Luxery shoes.webp'; 
import pic_trust_it_2 from '../pictures/Palace Hotel San Francisco.webp'; 
import pic_trust_it_3 from '../pictures/Airplane Maintenence.webp'; 
import pic_trust_it_4 from '../pictures/Petty Cash.webp'; 
import { Helmet }           from 'react-helmet';

const images =[
  {title:'Pic 1', picture: pic_trust_it_1, index:1}, 
  {title:'Pic 2', picture: pic_trust_it_2, index:2},
  {title:'Pic 3', picture: pic_trust_it_3, index:3},
  {title:'Pic 4', picture: pic_trust_it_4, index:4}, ] ;


const text_trust_it_1 = [{header: 'Chirp with Trust.it'},

<p key="paragraph1">

Welcome to Trust.it, you will find so many use cases for Trust.it. Chirp as example is the name we use for small chunks of socical media communication. Let's assume 
you have a Twitter like platform, but even the richest man of the world can not delete or change the content. That's Chirp. 
Once a Chirp is on the chain, it cannot be altered or deleted. 
Remember before sending, as each Chirp is permanent. When you want to correct something it can be addressed in subsequent Chirps, 
but the original Chirp message will remain on the Back-Step Blockchain.

Social media on the Back-Step Blockchain are the new trend. Every registred user has automatically 16 account groups, one of them is called Chirp. Try it.
Our Chirp feature, sets us apart from conventional social messaging systems.</p>,

{header: 'Simple Costs - Free Reading'},

<p>Sending a Chirp incurs a minimal fee, but reading messages is always free for everybody. 
  Chirp is cost-effective and transparent: You only pay for what you use. 
  No Chirping? No charges. You’ll enjoy our consumption-based pricing over standard subscriptions. Chirps cannot be encrypted and have the size  
  limmit of normal blocks. And here comes the surprise - <b>Chirps up to 1024 characters cost only 50% of the normal block Fee.</b></p>,

{header: 'Upcoming Features'},

<p>Trust.it and Chirp are continually evolving. We're excited to announce upcoming expansions to include media options like language support 
  for Chinese and Korean. We will also provide photo and video space for Chirp. </p>,

{header: 'Early Adoption Notice'},

  <p>
   Please note that Trust.it is still in its experimental phase. While we are committed to reliability and stability, the introduction of new features may briefly 
   interrupt the services for a little time. To protect your data, we recommend local backups of your blockchain.
  </p>,

    {header: 'More Trust.it'},
    <p>
      Trust.it exemplifies the simplicity and robustness of building applications atop the innovative
      Back-Step Blockchain. This platform serves as a secure repository for immutable records,
      guaranteeing the integrity of your data against any modification attempts. Each block accommodates
      up to 10 Million bytes of payload data, with a small portion reserved for administrative details such as user IDs
      or data types.
    </p>,
    
    <p> In its essence, Trust.it will offer four modes of operation: </p>,
      <p> 
      <ul>
        <li key="mode0"><strong>Chirp</strong> a social media implementation atop Trust.it </li>
        <li key="mode1"><strong>Basic Text Mode:</strong> Ideal for storing textual data, with optional encryption for enhanced privacy.</li>
        <li key="mode2"><strong>File Security Mode:</strong> Securely store large files on the chain itself. Limit at 100 MByte of data.</li>
        <li key="mode3"><strong>Sidecar mode:</strong> Hash of a file object on the chain with the actual data hosted externally. 
        This setup supports encrypted blocks for added confidentiality.</li>
      </ul>
      We are also encourage the community to contribute with innovative ideas.
    </p>,

    {header: 'Enhanced Privacy Features'},
    <p key="paragraph4">
      Trust.it incorporates a privacy mode, encrypting the block's payload to shield your data. This advanced
      encryption ensures that even identical texts, when encrypted, yield distinct blocks, maintaining your
      privacy without compromise. Future updates will introduce group functionalities, allowing shared access
      among selected users.
    </p>,

    {header: 'Fair Pricing Structure'},

    <p>
      Trust.it introduces a modest fee structure to maintain service quality and prevent spam. The fees, pegged
      currently to the US dollar, are as follows:</p>,
      <p>
      <ul>
        <li key="fee1">Standard Block:  2 Fee</li>
        <li key="fee2">Encrypted Block: 3 Fee</li>
        <li key="fee3">Large blocks:    add 2 or 3 Fee per MB</li>
      </ul>
      This fee covers indefinite storage, network transactions, maintenance costs, and ensuring a no-advertising experience on our platform. 
      But we also offer specialized rates for advertising blocks, with a commitment to maintaining a non-intrusive user experience.
    </p>,

    <p>
      Owners of the Back-Step Blockchain Generator creating their own applications enjoy the freedom to set their pricing, benefiting from volume discounts
      for transactions processed through Back-Step Blockchains.
    </p>
  ];
  
  const text_trust_it_2 = [
    {header: 'Technical Insights'},

    <p> For those interested in a deeper dive, each block in our blockchain comprises four distinct types of data: </p>,
    <ul>
        <li> <strong>Meta data:</strong> This is the DNA of each block, describing all block specific properties</li>
        <li> <strong>Payload data: </strong>These is the content specific to the application</li>
        <li> <strong>Payload security data:</strong> Payload security data secure the payload portion of the block. 
        The payload security data consists of the payload hash and the MoMu value. The MoMu value is forward chained among the blocks.</li>
        <li> <strong>Block security data:</strong>These are the Back-Step value the seal and the block hasch</li>
    </ul>,


    <p> To build an application like Trust.it we defined our own structure for the payload data representing all the fields required. Of course 
    this is 100% transparent, as everybody can read all the blocks. These fields reduce the data available for the application. They can be considered as payload metadata.
    There is a user ID field. This is used when you select to show only blocks with a specific user ID normally your own. Also an indication for user groups if required. 
    Finally there is a field defining the type of block from an application point of view. Trust.it for example uses 9 different blocks defining what purpose the block is used for.</p>,
    
    <p> Examples of block usage:</p>,
    
    [ 
    <ul>
      <li>Native block</li>
      <li>Encrypted block </li>
      <li>Reference block</li>   
      <li>Concatination block</li>
      <li>Group block</li>
      <li>Invalidate block</li>
      <li>Advertisement block</li> 
      <li>Message block</li> 
      <li>Payment block</li> 
      <li>Fee transfer block</li>
    </ul>
    ],

    
    <p>
      Notably, Trust.it has allocated 100TB of blockchain space, significantly larger than traditional blockchains, to accommodate
      a vast array of applications. With plans to expand capacity to 1PB, Trust.it is poised for rapid growth, supporting businesses
      and individual users alike.
    </p>,
    
    <p>
      To ensure integrity and system efficiency, Trust.it incorporates mechanisms for automatic or manual chain verification.
      In addition each user can verify the chain and even copy it to its local computer. To avoid verification attacks 
      and server overload the system will limit the number of parallel verifications to a certain percentage of the bandwidth. 
      User can create local copies of the chain and also verify their copies. Copies can continuously be appended as the chain grows. 
      To optimize the chain verification checkpoints can be set at the most recent verifications. This saves computing time at the client side.
    </p>
  ];
  

export class TrustIt extends React.Component
{
    //------------------------------------------------------------------
    //  Constructor
    //------------------------------------------------------------------
    constructor(props) 
    {
      super(props);
      this.component= "";
    }  

    componentDidMount()
    {
      //console.log('Component did mount in TrustIt');
      this.forceUpdate();

    };
   
    //------------------------------------------------------------------
    //  Prepare the render output
    //------------------------------------------------------------------
    render()
    { 
      if (Context.portrait)
        {
            this.component = 

              <div className={styles.backdrop.portrait}>

              <Helmet>
                <title>Trust.it online Back-Step for everybody</title>
                <meta name="description" content="Trust.t is your blockchain on the web. Fast, cheap and faszinating easy. The application is 100% fee.  " />
                <meta charset="utf-8" />
              </Helmet>
 
                  <div className={styles.grid_header_portrait}>
                      Trust.it - Your Chain! 
                  </div>

                  <div className={styles.grid_item_portrait}> 
                      <Paper key ={1} textLines = {text_trust_it_1}/> 
                  </div>

                  <div className={styles.grid_item_portrait}>
                      <Paper key ={2} textLines = {text_trust_it_2}/>
                  </div>
              </div>
        }
        else
        {
          this.component = 
          <div className={styles.backdrop}>
            
             <Helmet>
              <title>Trust.it the online app</title>
              <meta name="description" content="Trust.t is your blockchain on the web. Fast, cheap and faszinating easy. The application is 100% fee.  " />
              <meta charset="utf-8" />
              </Helmet>

              <div className={styles.grid_container}>

                  <div className={styles.grid_header}>
                  <h6> Trust.it - Your Blockchain! </h6>
                </div>
                <div className={styles.grid_item}> 
                < Newspaper key ={3} textLines = {text_trust_it_1}/> 
                </div>

                <div className={styles.grid_item}>
                  <Newspaper  key ={4} textLines = {text_trust_it_2}/>
                </div>

              </div>
        </div>

        }
      return this.component ;
    }
}  

