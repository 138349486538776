
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styles from './hamburger.module.css';

const Hamburger = () => 
{
  const [isOpen, setIsOpen] = useState(false);
  const [num, setNum]       = useState(0);
  const [kum, setkum]       = useState(0);
  
  const navigate            = useNavigate();

  const resetMenu     = () => setIsOpen(false);
  const resetMenuHome = () => setIsOpen(false);

  const overviewMenu = () => 
  {
    const newNum = (num + 1) % 5;
    setNum(newNum);
    setIsOpen(false);
    navigate("/overview_portrait", { state: { location: newNum, unique: Date.now() }, });
  }

  const productsMenu =() =>
  {
    const newkum = (kum + 1) % 5;
    setkum(newkum);
    setIsOpen(false);
    navigate("/products", { state: { location: newkum, unique: Date.now() }, });
  }


  const toggleMenu = () => setIsOpen(!isOpen);
  const toTop = () => {window.scrollTo({ top: 0, left: 0, behavior: "smooth" })};

  const renderMenu = () => (
    <div className={styles.hamburger_icon} onClick={toggleMenu}>
      <div className={styles.bar} />
      <div className={styles.bar} />
      <div className={styles.bar} />

      <div className={styles.menu_list}>
        <ul>
          <li> <Link to="/"             onClick={resetMenuHome}> Home     </Link> </li>

          <li> <a onClick={overviewMenu} style={{cursor: "pointer" }}>  Overview </a> </li>
          <li> <a onClick={productsMenu} style={{cursor: "pointer"}}>   Products </a> </li>

          <li> <Link to="/story"        onClick={resetMenu}> Story        </Link> </li>
          <li> <Link to="/investors"    onClick={resetMenu}> Investors    </Link> </li>
          <li> <Link to="/publications" onClick={resetMenu}> Publications </Link> </li>
          <li> <Link to="/contact"      onClick={resetMenu}> Contact      </Link> </li>
          <li> <Link to="/about"        onClick={resetMenu}> About        </Link> </li>
        </ul>
      </div>
    </div>
  );

  return isOpen ? ( renderMenu()) : (
    <div className={styles.hamburger_icon} onClick={toggleMenu}>
      <div className={styles.bar} />
      <div className={styles.bar} />
      <div className={styles.bar} />
    </div>
  );
};

export default Hamburger;




