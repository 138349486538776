import React     from 'react'; 

import Newspaper  from '../components/Newspaper.jsx';
import Newspaper_4_col     from '../components/Newspaper_4_col.jsx';
import Paper      from '../components/Paper.jsx';
import styles     from './how_it_works.module.css';
import {Context}  from '../services/context.js'
import { Helmet } from 'react-helmet';
import classNames from 'classnames' 

import block_security_generation from '../pictures/Block Security Generation.png';
import seal  from '../pictures/Seal-before-manipulation.jpg';
import pic_3 from '../pictures/Two Chains.png';
import pic_4 from '../pictures/Blockchain Foldback_Front of chain.png';
import pic_5 from '../pictures/Blockchain Paper Diagrams-01.png';
import pic_6 from '../pictures/Block encryption with modifier.png';
import pic_7 from '../pictures/Block decryption with modifier.png';
import pic_8 from '../pictures/Block Structure.png';


    const how_it_works  = [{header: 'How does Back-Step work'} ,
    <p>We assume that you know how traditional blockchains work.</p>,
    <p> All blockchains require a hash value over the contents of the block. This value is then forwarded into the next block building a chain of blocks. 
      This guarantees that every modification of a block changes the hash value of that block and all following blocks. But this alone is not enough. If someone modifies an existing block 
      and re-calculates the hash of all following blocks and then claims that his chain is original the security is totally lost. This must be prevented at any cost. That is why special measures are 
      required to detect such modifations immediatelly. The current solutions require a lot of computation work and / or a lot of network nodes which are all completely 
      independant of each other. This limits applications for blockchains, as they becomes slow, expensive and your data are spread all over the world. There is another factor which is often overloked. 
      When a blockchain is replicated at 10.000 nodes, each node need storage to store the complete chain. The cost of this storage is at the end paid by the users, 
      most time hidden in transaction fees.</p>,
     
    <p> 
      The <b>Back-Step Blockchain</b> uses a different mechanism to secure the chain. Lightning fast, very affordable and no need for many nodes. 
      Technically, each block gets two additional values from a cryptographic generartor. These values become part of the block. 
      The specialty is that these values are backwards chained. From each block in the chain you can easily calculated the value in the previous block but not vice versa.
      This builds a chain from the current block back to the very first block of the chain. But, nobody can calculate the value of the next future block. This prevents faked blocks placed at the end of the chain.
      I mmentioned two values, the second one prevents any modification somewhere in an existing chain. The second value creates a seal. Both values are almost immediatelly available in the generator. 
      Calculating the back-step value and the forward hash are fast operations with a fixed number of cycles. The limiting factor for the speed of the chain is often the disk write operation.   
      The diagrams show the flow of the chain. These are the basic principles. More details can be seen in white papers under <b>Publications</b>. 
    </p>];
    
    const chain_on_chain  = [ {header: 'Chain on Chain - Parent/Child Mode - Virtual Chain'},
     <p>The Back-Step Blockchain can hold long blocks up to 100 MB in its payload. The required length of the block is determined by the application requirenments. Each block can have a different length. 
      We call this variable block size chains. Initially, we used fixed length blocks. A part of the block, arround 1000 byte were available for the user data, called payload. 
      With the invention of the vaiable block size Back-Step Blockchains you are flexible to use payloads from 16 Byte up to 100 MB. This paylod is hashed first and then placed into the block. 
      There is no previous payload hash used. Instead the payload hash value is multiplied with the pervious MoMu value and reduced modulo p (highest prime below 2^32). 
      This value, we call it MoMu is part of the block and hashed. If multiple chains (reudndancy setup) feed in the payload in reverse order the MoMu is identical after all payload blocks are inserted, 
      independent of the order. The order of the multiplications is irrelevant. 
      This helps to synchronize multiple chains. Example: Three chains feed in the same payload blocks, but in random order. After all blocks are feed the MoMu value will be identical, 
      while the block hash is definitly not. This allows each chain in a cluster to use their own random start values. An additional security factor.
      <br/>
      With this technology it is possible to place other chains as payload into the Back-Step Blockchain. These other chain can the disable their block security mechanism and participate from the 
      performance advantages of the Back-Step Blockchain. A "proof of work" chain can give up its 10 minutes proof of work and the Merkle hash. Each transaction could get its own block.    
    </p>];
    
    const security_by_chip  = [{header: 'Security by chip...'},
    <p>The core of the Back-Step Blockchan is a generator inside a protected chip. The technology is similar to what you know from the chips in your credit card or your bank card.
      This provides the state of the art security. Details can be found in the patent papers. Beside the prototypes we will provide different versions of the product for different use cases.
      All version of the Back-Step Blockchain will process more than 1000 blocks a second. The high end generator will be able to process above 1 million blocks per second. 
      Because all harware can fail, it is recommended to build redundancy, preferred with 2 out of 3 or 3 out of 5 servers. 
    </p>];

    const seal_momu = [{header: 'Seal and MoMu for Security'},
      <p>
        The seal is a security value that cannot be forged, even not by the owner of the Back-Step Generator. It's chain runs in parallel with the Back-Step generator but with independend values.
        To avoid that someone calculates backwards from the seal to the one way chain the result of the XOR operation is one way encrypted. The diagram shows this operation.

        The MoMu value for the current block is calculated using the MoMu of the previous block multiplied by the payload hash of the current hash modulo Prime. Prime is a large prime number.
        MoMu is the mechanism to keep chains in a cluster synchron even if individual blocks are inserted in different sequences. 

      </p>
    ]

    const do_we_need_networks = [{header: 'Do we need networks?'},
    <p> For what? In an application where a Back-Step Blockchain is integrated in a ID card or an passport only an application interface is needed, often wireless. 
        For server based applications the Back-Step Blockchain should be implemented in a redundancy mode to connect to 2 or 4 other Back-Step Blockchains in a redundant pool. 
        Here a high speed connection is preferrable. There might be also application where the customer wants chains per country, region or even city, then the servers need to be connected.
        For standard applications the block input is provided by a network often via internet. If the chain is used in a black-box of an airplain the input may be presented without a network. 
        No network is needed for block autheticity. Now, huge clusters with thousands of nodes as known from traditional blockchains are obsolate .   
    </p> ];
  
  
  const redundancy = [{header: 'How does redundancy work?'},
    <p>The redundancy concept provides provides high availability and perfect security.
      Each Back-Step Blockchain is in constant contact to the other chains in the cluster. Users can be assigned to one of the clusters, allowing some kind of load distribution.
      Each chain will receive payload from their assigned users. 
      If all chains are operational each chain processes the received data and sends copies to the other chains in the cluster. Because each chain has its own set of keys, 
      back-step values, the seal values and the encrypted payload are different on each chain. Non encrypted contents has identical payload on cluster chains.  </p> ];

   
   
  const data_encryption = [{header: 'Encryption for your data'},
  <p>Your encrypted data is displayed on the blockchain, allowing everyone to see the encrypted bytes. Naturally, only you can access the plain, decrypted data. 
    However, entities such as hackers might be interested in attempting to decrypt these bytes, potentially to gain access to sensitive information like your 
    cryptocurrency secrets. We anticipate that experts equipped with the latest cryptoanalytic skills will challenge your data’s security. Our encryption is designed to 
    withstand such professional attacks, ensuring your data remains safe. This level of security extends beyond what typical encryption libraries offer. 
    Relying on secrecy in your encryption design isn’t sufficient; robust security measures must remain effective even when the design is public. We have implemented 
    two distinct encryption algorithms, each building on the well-studied data flow principles of AES, which we believe has been thoroughly analyzed over the past 25 
    years. Initially, the data are encrypted in 16-byte segments (hereafter referred to as 'data units' to avoid confusion with blockchain blocks) using standard AES in
    CBC mode, with two unique 256-bit key for each data unit. After the initial encryption (key 1), all data units are XORed with a modifier constructed from the data itself. 
    The data units are then encrypted in reverse (key 2), starting from the last unit to the first, using a separate, independently generated session key. This backward 
    encryption phase can be extended beyond the standard 14 rounds. Additionally, we can vary the S-Boxes used in the backward encryption with content of high strength. 
    We believe this combination of techniques provides a trusted environment for your data. Now you understand why a block ensuring privacy incurs an additional Fee.</p> ];
  

  export class HowItWorks extends React.Component  
  { 

      //------------------------------------------------------------------
      //  Constructor
      //------------------------------------------------------------------
      constructor(props) 
      {
        super(props);
        this.component= "";
      }  
      
  
      componentDidMount()
      {
        // console.log('Component did mount in About Us');
        this.forceUpdate();
        window.scrollTo({top:0, left: 0, behavious: 'smooth'});
      };
      
      // className={styles.backdrop}
      //---------------------------------------------------------------------------
      //  Prepare for portrait devices first
      //---------------------------------------------------------------------------
      render()
      { 
        if (Context.portrait)
        {
            this.component = 
            <div className={styles.backdrop_portrait}>

              <Helmet>
                <title>How Back-Step Blockchain works</title>
                <meta name="description" content="Detailed explanation of the invention with diagrams and flowcharts" />
                <meta charset="utf-8" />
              </Helmet>

    
              <div className={styles.portrait_header}>
              How it works...
              </div>

                      {/* How does Back-Step work*/}
                      <div className={styles.portrait_item} > 
                      < Paper textLines = {how_it_works}/> 
                      </div>


                      <div className={styles.portrait_image}>
                        <p>Hash and Back-Step value flow into different directions</p>
                        <img src={pic_3} width='100' alt="pic_introduction" />
                      </div>

                      <div className={styles.portrait_image}>
                        <p>Feedback link of the first Back-Step value to the hash chain</p>
                        <img src={pic_4} width='100' alt="pic_introduction" />
                      </div>
  
                      <div className={styles.portrait_image}>
                        <p>The basic Back-Step idea which lead to the patent applications</p>
                        <img src={pic_5} width='100' alt="pic_introduction" />
                      </div>

                      {/* Chain on chain*/}
                      <div className={styles.portrait_item}>
                        <Paper  textLines = {chain_on_chain}/>
                      </div>

                      <div className={styles.portrait_image}>
                        <p>Diagram with payload hash and block hash</p>
                        <img src={block_security_generation} width='250' alt="pic_introduction" />
                      </div>
                      
                    
                      {/* Security by chip*/}
                      <div className={styles.portrait_item}>
                        <Paper  textLines = {security_by_chip }/>
                      </div>

                      <div className={styles.portrait_image}>
                        <p>Block Structure and processing</p>
                        <img src={pic_8} width='40' alt="pic_introduction" />
                      </div>


                      {/* Do we need networks*/}
                      <div className={styles.portrait_item}>
                        <Paper  textLines = {do_we_need_networks}/>
                      </div>


                      {/* How does redundancy work*/}
                      <div className={styles.portrait_item} > 
                        <Paper  textLines = {redundancy}/>
                      </div>

                      {/*Encryption for your data*/}
                      <div className={styles.portrait_item}>
                        <Paper textLines = {data_encryption}/>
                      </div>

                      <div  className={styles.portrait_image}>
                        <img src={pic_6}  width='250' alt="encryption concept" />
                    </div>
              
                    <div  className={styles.portrait_image}>
                      <img src={pic_7}  width='250' alt="decryption concept" />
                    </div>

                  </div>
               {/*} </div> */}          
        }
        else
        //---------------------------------------------------------------------------
        //  Prepare for Landscape devices herefirst
        //---------------------------------------------------------------------------
        {
          this.component = 
          <div className={styles.backdrop}>

              <Helmet>
                <title>How Back-Step Blockchain works</title>
                <meta name="description" content="Detailed explanation of the invention with diagrams and flowcharts" />
                <meta charset="utf-8" />
              </Helmet>

              <div className={styles.grid_container}>

                {/* How does Back-Step work*/}
                <div className={classNames(styles.grid_item, )}> 
                  < Newspaper textLines = {how_it_works}/> 
                </div>

                <div className={styles.grid_image_right}>
                  <p>The basic Back-Step idea which lead to the patent applications</p>
                  <img src={pic_3} width='100' alt="pic_introduction" />
                </div>

                <div className={styles.grid_image_left}>
                  <p>Feedback link of the first Back-Step value to the hash chain </p>
                  <img src={pic_4} width='100' alt="pic_introduction" />
                </div>

                <div className={styles.grid_image_right}>
                  <p>Insertion of the Back-Step value into the chain</p>
                  <img src={pic_5} width='100' alt="pic_introduction" />
                </div>


                {/* Seal and MoMu for security*/}
                <div className={styles.grid_item}>
                  <Newspaper  textLines = {seal_momu}/>
                </div>


                <div className={styles.grid_image_right}>
                  <p>The effect of the seal</p>
                  <img src={seal} width='100' alt="seal protection" />
                </div>



                {/* Security by chip*/}
                <div className={styles.grid_item}>
                  <Newspaper  textLines = {security_by_chip}/>
                </div>


                <div className={styles.grid_image_right}>
                  <img src={block_security_generation} width='250' alt="block_security_generation"/>
                </div>
              

               {/* Chain on chain*/}
               <div className={styles.grid_item}>
                  <Newspaper  textLines = {chain_on_chain}/>
                </div>

 
                 
              
   
                <div className={styles.grid_image_portrait}>
                  <p>Block Structure and processing</p>
                  <img src={pic_8} width='40' alt="pic_introduction" />
                </div>



                {/* Do we need networks*/}
                <div className={styles.grid_item}>
                  <Newspaper  textLines = {do_we_need_networks}/>
                </div>


                {/* How does redundancy work*/}
                <div className={styles.grid_item_right}>
                  <Newspaper  textLines = {redundancy}/>
                </div>


                {/*Encryption for your data*/}
                <div className={styles.grid_item_wide}>
                  <Newspaper_4_col  textLines = {data_encryption}/>
                </div>

                <div  className={styles.grid_item_wide}>
                  <img src={pic_6} className={styles.rounded_img} width='150' alt="encryption concept" />
              </div>
        
              <div  className={styles.grid_item_wide}>
                <img src={pic_7} className={styles.rounded_img} width='150' alt="decryption concept" />
              </div>

          </div>    
        </div>

        }
     
        return this.component ;
      }
  
}