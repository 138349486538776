
import React, { Component, useState } from 'react';
import { Routes, Route, HashRouter as Router } from "react-router-dom";
import styles               from './navigation.module.css';
import {Context}            from './services/context.js'
import { Link, useNavigate } from 'react-router-dom';
import Home                 from "./pages/home.jsx";
import { Overview }         from "./pages/overview.jsx";

import Products             from "./pages/products.jsx";
import { About }            from "./pages/about-us.jsx";
import { Investors }        from "./pages/investors.jsx";
import ContactForm          from './pages/contact_form.jsx'; 
import { Story }            from './pages/story.jsx';
import {Publications}       from './pages/publications.jsx';


//---------------------------------------------------------
//  Function
//---------------------------------------------------------

export const Navigation = () =>
{
  
    const navigate            = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [num, setNum]       = useState(0);
    const [kum, setkum]       = useState(0);
    
    // Initialize the selected object with all keys set to false
    
    const selected = 
    {
        home:false,
        overview: false,
        story: false,
        products: false,
        investors: false,
        publications : false,
        contact: false,
        about: false
    };

    
    
    const overviewMenu = () => 
    {
        const newNum = (num + 1) % 5;
        setNum(newNum);
        setIsOpen(false);
        navigate("/overview", { state: { location: newNum, unique: Date.now() }, });
    }
      
    const productsMenu = () =>
    {
        const newkum = (kum + 1) % 5;
        setkum(newkum);
        setIsOpen(false);
        navigate("/products", { state: { location: newkum, unique: Date.now() }, });
    }
   
    const toTop = () => {window.scrollTo({ top: 0, left: 0, behavior: "smooth" })};


                                                                
    //----------------------------------------------------------------
    //  Render for Desktop (small devices are in App.jsx)
    //----------------------------------------------------------------
    const renderData = () => 
    (
        <div> 
            <div className = {styles.navigation} >
                <p className= {styles.button}  onClick={() => toTop( 'home')} >         <Link to = "/"                >  Home            </Link></p>

                <p className= {styles.button}  onClick={() => toTop( 'overview')} >     <a  onClick={overviewMenu} style={{cursor: "pointer"}}>   Overview </a></p>
                <p className= {styles.button}  onClick={() => toTop( 'products')} >     <a  onClick={productsMenu} style={{cursor: "pointer"}}>   Products </a></p>

                <p className= {styles.button}  onClick={() => toTop( 'story')} >        <Link to = "/story"           >  Story           </Link></p>                    
                <p className= {styles.button}  onClick={() => toTop( 'investors')} >    <Link to = "/investors"       >  Investors       </Link></p>
                <p className= {styles.button}  onClick={() => toTop( 'publications')}>  <Link to = "/publications"    >  Publications     </Link></p>                        
                <p className= {styles.button}  onClick={() => toTop( 'contact')} >      <Link to = "/contact"         >  Contact         </Link></p>
                <p className= {styles.button}  onClick={() => toTop( 'about')} >        <Link to = "/about"           >  About           </Link></p>
            </div>

            <br/>

            </div>        
    )      
       
    return  (renderData())

}

