//------------ For portrait only -------------

import React, { useState, useEffect, useRef } from 'react'; 

import {What_is_BSB}    from './what_is_BSB.jsx';
import {UseCases}       from './use_cases.jsx';
import {Money}          from './money.jsx';
import {HowItWorks}     from './how_it_works.jsx';
import {PromptSecurity} from './prompt_security.jsx';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styles           from './overview.module.css';
import { Helmet }       from 'react-helmet';

import MyCardPortrait     from '../components/MyCardPortrait.js';
import MyCard     from '../components/MyCardV2';

import pic_home_1 from '../pictures/Blockchain box with people.png';
import pic_home_2 from '../pictures/what_can_it_do.jpg';
import pic_home_4 from '../pictures/Currency.webp'; 
import pic_home_3 from '../pictures/University.webp';
import pic_home_5 from '../pictures/AI agent bought handbag.webp';
import pic_home_6 from '../pictures/API.webp';

// import { Alert } from "react-bootstrap";

const images =[
  {title:'Pic 1', picture: pic_home_1, index:1},
  {title:'Pic 2', picture: pic_home_2, index:2},
  {title:'Pic 3', picture: pic_home_3, index:3}, 
  {title:'Pic 4', picture: pic_home_4, index:4},
  {title:'Pic 5', picture: pic_home_5, index:5},
  {title:'Pic 6', picture: pic_home_6, index:6}] ;
 
const title_home_1 = `What is Back-Step Blockchain?`;
const text_home_1  = `Back-Step Blockchain is a new chain technology for existing and new blockchain applications. It has many advantages that it will dominate the blockchain market.`     

const title_home_2 = "Thousand+++ use cases"

const text_home_2  = `From private life, via AI models, to small and multinational businesses. Don't forget governments and plenty of technical use cases.`

const title_home_3 ="Back-Step Blockchain - How it works";
const text_home_3  =`We explain how it works in an overview for people with blockchain background. `;

const title_home_4 ="Trustful Digital Currencies.";
const text_home_4  =`Back-Step Blockchain opens the door for a new class of cryptocurrencies. Trustfull, stable and fast. Exactly what you need for your daily life.`;

const title_home_5 = "Back-Step Blockchain for AI-Security";
const text_home_5  = [<p>Artificial Intelligence inspired some bad guys to use prompt attacks. See how 
Back-Step Blockchain Proxy helps.
</p>];

const title_home_6 = "API to create your own app.";
const text_home_6  = [<p>You know your customers better than enybody else. Create apps for them they couldn't belive they were possible. 
We help you learn the Back-Step Blockchain and then you change the market!
</p>, 
  
<p>
More details follow soon...</p>];

//-----------------------------------------------------
//  Function code converted from class code
//-----------------------------------------------------
const OverviewPortrait = (props) => 
{
  const location = useLocation();

  // Replicating componentDidUpdate for location.state
  const prevLocationState = useRef(location.state);
  
  useEffect(() => 
  {
    if (prevLocationState.current !== location.state) 
    {
      if (selectedForm > 0)
      {
        setSelectedForm(0)
      }
      prevLocationState.current = location.state;
    }
  }, [location.state]);


  // Selected form is initialized from props.form (if provided), otherwise null (grid view)

  const [selectedForm, setSelectedForm] = useState(props.form || 0);

  // This function is equivalent to the detail_card method in your class.
  
  const detailCard = (select) =>
  {  
    setSelectedForm(select)
  }  

  const mainForm = (
    <div className={styles.backdrop_portrait}>
      <div className={styles.grid_container_portrait}>
        <Helmet>
          <title>Overview showing many aspects of the invention</title>
          <meta name="description" content="Short description of the details explained in own pages." />
          <meta charSet="utf-8" />
        </Helmet>

        <div className={styles.grid_header_portrait}></div>

        <div className={styles.grid_item_portrait}>
          <MyCardPortrait 
            title={title_home_1}
            img={pic_home_1} 
            text={text_home_1}
            btn="See more..."
            detail={() => detailCard(1)}
          />
        </div>

        <div className={styles.grid_item_portrait}>
          <MyCardPortrait 
            title={title_home_2}
            img={pic_home_2} 
            text={text_home_2}
            btn="See more..."
            detail={() => detailCard(2)}
          />
        </div>

        <div className={styles.grid_item_portrait}>
          <MyCardPortrait 
            title={title_home_3}
            img={pic_home_3} 
            text={text_home_3}
            btn="See more..."
            detail={() => detailCard(3)}
          />
        </div>

        <div className={styles.grid_item_portrait}>
          <MyCardPortrait 
            title={title_home_4}
            img={pic_home_4} 
            text={text_home_4}
            btn="See more..."
            detail={() => detailCard(4)}
          />
        </div>

        <div className={styles.grid_item_portrait}>
          <MyCardPortrait 
            title={title_home_5}
            img={pic_home_5} 
            text={text_home_5}
            btn="See more..."
            detail={() => detailCard(5)}
          />
        </div>

        <div className={styles.grid_item_portrait}>
          <MyCardPortrait 
            title={title_home_6}
            img={pic_home_6} 
            text={text_home_6}
            btn="See more..."
            detail={() => detailCard(6)}
          />
        </div>
      </div>
    </div>
  );

  // Decide which component to render based on selectedForm.
  // (Note: This mimics the switch-case in your class render method.)

  let componentToRender;

  switch (selectedForm) 
  {
    case 1:
      componentToRender = <div><What_is_BSB /></div>;
      break;
    case 2:
      componentToRender = <div><UseCases /></div>;
      break;
    case 3:
      componentToRender = <div><HowItWorks /></div>;
      break;
    case 4:
      componentToRender = <div><Money /></div>;
      break;
    case 5:
      componentToRender = <div><PromptSecurity /></div>;
      break;
    case 6:
      componentToRender = mainForm;
      break;
    default:
      componentToRender = mainForm;
  }

  // Replicating the resetting of selectedForm as in your class's render
  // (this will cause the detail view to only be visible for one render cycle).
  
  useEffect(() => 
  {
    if (selectedForm !== 0) 
    {
      //setSelectedForm(0);
      //selectedForm = 0;

    }
  }, [selectedForm]);

  return componentToRender;
};

export default OverviewPortrait;




















/*


//------------------------------------------------
//  Class
//------------------------------------------------
class OverviewPortrait extends React.Component  
{

  //------------------------------------------------
  //  Constructor
  //------------------------------------------------
  constructor(props) 
  {    
    super(props);
    //console.log('Constructor', props.form);
    this.mainform           = ''
    this.selected_form      = props.form;
    this.num                = 0
    this.detail_card        =  this.detail_card.bind(this);
   
  }

  //------------------------------------------------
  //  Component Mount
  //------------------------------------------------
  componentDidMount()
  {
      this.forceUpdate();
  }
  // 👇️ scroll to top on page load

   //className = {styles.backdrop}

  //------------------------------------------------
  //  Component did update
  //------------------------------------------------
  componentDidUpdate(prevProps)
  {
    if (this.props.location.state !== prevProps.location.state) 
    {
      this.num = this.props.location.state // Do something with the new state
      alert('overview component did update')  
    }
  }



  //-----------------------------------------------
  //  Methods to show the full details of a topic
  //----------------------------------------------- 
  detail_card(select)
  {
    //console.log('detail_card', select);
    this.selected_form = select;
    this.forceUpdate();
  }
   

  //------------------------------------------------------
  //  Return the render data
  //------------------------------------------------------
  render()
  {
    const { myCustomVar } = this.props.location.state || {};
    this.mainform = 
    <div  className={styles.backdrop_portrait}>
      <div className={styles.grid_container_portrait}>

        <Helmet>
          <title>Overview showing many aspects of the invention</title>
          <meta name="description" content="Short description of the details explained in own pages." />
          <meta charset="utf-8" />
        </Helmet>

        <div className={styles.grid_header_portrait}>   </div>

        <div className={styles.grid_item_portrait}>
          <MyCardPortrait 
            title={title_home_1}
            img={pic_home_1} 
            text={text_home_1}
            btn="See more..."
            detail={()=>this.detail_card(1)}>
          </MyCardPortrait> 
        </div>

        <div className={styles.grid_item_portrait}>
          <MyCardPortrait 
            title={title_home_2}
            img={pic_home_2} 
            text={text_home_2}
            btn="See more..."
            detail={()=>this.detail_card(2)}>
          </MyCardPortrait>  
          </div>

        <div className={styles.grid_item_portrait}>           
          <MyCardPortrait 
            title={title_home_3}
            img={pic_home_3} 
            text={text_home_3}
            btn="See more..."
            detail={()=>this.detail_card(3)}>            
          </MyCardPortrait>  
        </div>

        <div className={styles.grid_item_portrait}>
          <MyCardPortrait 
            title={title_home_4}
            img={pic_home_4} 
            text={text_home_4}
            btn="See more..."
            detail={()=>this.detail_card(4)}>  
          </MyCardPortrait>  
        </div>

        <div className={styles.grid_item_portrait}>
          <MyCardPortrait 
            title={title_home_5}
            img={pic_home_5} 
            text={text_home_5}
            btn="See more..."
            detail={()=>this.detail_card(5)}>            
          </MyCardPortrait>  
          </div>

        <div className={styles.grid_item_portrait}>
        <MyCardPortrait 
          title={title_home_6}
          img={pic_home_6} 
          text={text_home_6}
          btn="See more..."
          detail={()=>this.detail_card(6)}>            
        </MyCardPortrait>  
        </div>
 
      </div>
    </div>

    switch (this.selected_form)
    {
      case 1:
        this.component = <div><What_is_BSB/></div>;
        break;

        case 2:  
        this.component = <div><UseCases/></div>;
        break;

        case 3:  
        this.component = <div><HowItWorks/></div>;
        break;

        case 4:  
        this.component = <div><Money/></div>;
        break;

        case 5:  
        this.component = <div><PromptSecurity/></div>;
        break;

        case 6:  
        this.component = this.mainform;
        break;

      default:
        this.component = this.mainform;
    }    
    this.selected_form = this.mainform;
    return  this.component ;
  }
}
export default withRouter(OverviewPortrait)
*/