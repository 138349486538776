import React     from 'react'; 
import styles    from './bsb_b010.module.css';
import Newspaper from '../components/Newspaper.jsx';
import Paper     from '../components/Paper.jsx';
import {Context} from '../services/context.js';

import pic_BSB100_board from  '../pictures/PCI_express_front.png';
import { Helmet }       from 'react-helmet';

const text_BSB_B010_1 = [
    {header: 'Powerful Back-Step Generator'},

    <p>
     This product is currently under development. Prototype availability will be announced here.
    </p>,

    {header: 'Functions'},
    
    <p>
      The BSB-010 is the central plug in board for a Back-Step Blockchain server. The sever requires a free PCIe x8 slot. Drivers are currently available for Linux under Node JS.
      A sample middleware similar as used for Trust.it will be provided.
      The board has multiple protection layers to protect it against logical and physical attacks. All secrets are self generated and not known by the owner of the board or anybody else.
      The chain can be stopped using a separate external switch. This is required before any physical access to the server. For highest throughput we recommend to use local disk pools with fail-protection.
      Applications using the blockchain data may run on the same server if enough CPU cores and memory is provided. We recommend to provide a separate disk pool for the applications.
    </p>,
    
    <p> The Middle-ware will provide the interface to the chain media (disk or SSD array) and to the other Back-Step Blockchain servers if you use a redundant setup. 
        A independent tool is provided to verify the integrity of the chain. 
    </p>,

    {header: 'Management'},
    
    <p>
        product provides a set of management functions, statistics and status reports. These functions are separated from the normal operational functions and use a dedicated ethernet port.
        The product is manufactured in Singapore and comes with a one year warrenty.
    </p>,

  ];

  
const text_BSB_B010_2 = [

    {header: 'Prelimitary Technical/Operational Data'},
    
      <ul>
        <li key="mode1"><strong>Performance:</strong> average 2000 blocks per second. Application and middleware computation not included, just the Back-Step generator speed.</li>
        <li key="mode2"><strong>Power consumption:</strong> ~15 Watt at full load.</li>
        <li key="mode3"><strong>Startup time:</strong> 10 seconds after power on.</li>
        <li key="mode4"><strong>Pipelining: </strong> 3 blocks, one in operation, one in output buffer and one in input queue. Middleware must ensure correct sequensial block numbers. </li>
        <li key="mode5"><strong>Hash: </strong> A separate payload SHA3 hash function is proviced for parallel hashing of queued blocks. </li>
      </ul>
  ];
  

  export class BSB_B010 extends React.Component
  {

    constructor(props)
    {
      super(props);
      this.component= "";
    }  
    

    componentDidMount()
    {
      this.forceUpdate();
    }
   
    //------------------------------------------------------------------
    //  Prepare the render output
    //------------------------------------------------------------------
    render()
    { 

      if (Context.portrait)
      {
          console.log('BSB_B010 portrait');

          this.component =              
    
            <div className={styles.portrait_backdrop}>
 
              <Helmet>
                <title>BSB B010</title>
                <meta name="description" content="Explains BSB_B010 hardware generator board for Back-Step Blockchain. Shows picture" />
                <meta charset="utf-8" />
              </Helmet>

              <div className={styles.portrait_header}>
                Back-Step Generator for servers
              </div>

              <div className={styles.portrait_item}> 
                < Paper textLines = {text_BSB_B010_1}/> 
              </div>

              <div className={styles.portrait_item}>
                  <Paper  textLines = {text_BSB_B010_2}/>
              </div>
 
              <div className={styles.portrait_image}>
                <img src={pic_BSB100_board} alt="picture of BSB_B010 board"/>
                <p>PCIe Gen3 x8 bus.</p> 
                <p>Anti-TamperPCIe touch sensor.</p> 
                <p>Multiple intrusion detecten sensors.</p>
                <p>Backup battery life `{'>'}`10 years.</p> 
              </div>
              
          </div>

      }
      else
      {

        this.component = 
          <div className={styles.backdrop}>

                <Helmet>
                  <title>BSB B010</title>
                  <meta name="description" content="Explains BSB_B010 hardware generator board for Back-Step Blockchain. Shows picture" />
                  <meta charset="utf-8" />
                </Helmet>

              <div className={styles.grid_container}>

                  <div className={styles.grid_header}>
                      <h4> Back-Step Generator for servers </h4>
                  </div>

                  <div className={styles.grid_item_left_row_2}> 
                      < Newspaper textLines = {text_BSB_B010_1}/> 
                  </div>

                  <div className={styles.grid_item_left_row_3}>
                      <Paper  textLines = {text_BSB_B010_2}/>
                  </div>

                  <div class_name ={styles.grid_image_right}>
                    <img src={pic_BSB100_board} width='250' alt="picture BSB_B010 server board" />
                    <p></p> 
                  </div>

 

              </div>
        </div>
      
      }
      return this.component ;
    }
}  

