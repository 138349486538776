
import React            from 'react';

import {Routes, Route, HashRouter as Router } from 'react-router-dom';

import {Navigation}     from './navigation.jsx';
import Footer           from './components/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Context}        from './services/context.js'; 
import Hamburger        from "./components/hamburger.jsx";

import Home                 from "./pages/home";
import { Overview }         from "./pages/overview.jsx";
import OverviewPortrait     from "./pages/overview_portrait.jsx";
import Products             from "./pages/products.jsx";
import { About }            from "./pages/about-us";
import { Investors }        from "./pages/investors.jsx";
import ContactForm          from './pages/contact_form';
import {Publications}       from './pages/publications.jsx';
import { Story }            from './pages/story.jsx';


const divStyle = {
  backgroundPosition: 'center',
  backgroundColor: 'rgb(211,211,211)',
}; 


export class App extends React.Component 
{
  constructor()
  {
    super()
    this.portrait = Context.portrait;
    this.state = {currentTime : new Date().toISOString() };
  }

  render()
  {
    if (Context.portrait === true)
    {
      this.mumu ++
      this.mumu = this.mumu % 5
      this.component =
        <Router>
            <div>
            <Hamburger/> 
            <Routes>  
              <Route path="/"                   element={<Home />} />
              <Route path="/overview_portrait"  element={<OverviewPortrait />} />
              <Route path="/story"              element={<Story  />} />
              <Route path="/products"           element={<Products />} />
              <Route path="/publications"       element={<Publications />} />
              <Route path="/investors"          element={<Investors />} />
              <Route path="/contact"            element={<ContactForm  />} />
              <Route path="/about"              element={<About  />} />
              <Route path="*"	                  element={<Home />}/>
            </Routes>
        </div>
        <Footer/>
        </Router>
  
    }
    else
    {
      this.component =  
      <Router>
        <div>
          <Navigation />
          <Routes>  
              <Route path="/"                   element={<Home />} />
              <Route path="/overview"           element={<Overview/>} />
              <Route path="/story"              element={<Story  />} />
              <Route path="/products"           element={<Products />} />
              <Route path="/publications"       element={<Publications />} />
              <Route path="/investors"          element={<Investors />} />
              <Route path="/contact"            element={<ContactForm  />} />
              <Route path="/about"              element={<About  />} />
              <Route path="*"	                  element={<Home />}/>
            </Routes>
        </div>  
        <Footer/>      
      </Router>
    };
  
    return( this.component);
  }
}

