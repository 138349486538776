import React, { Component } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import press_release_1 from "../pictures/PressRelease1.pdf"
import white_paper_ai  from "../pictures/White Paper Prompt security.pdf"
import patent from '../pictures/Patent Certificate.pdf'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/4.8.69/pdf.worker.mjs`;

const pdfjs_version = pdfjs.version

class PDFViewer extends Component 
{
    constructor(props) 
    {
        super(props);
        //this.file = props.file
    }  

  state = { numPages: null };
  onDocumentLoadSuccess = ({ numPages }) => 
  {
    this.setState({ numPages });
  };


  render() 
  {
    const { numPages } = this.state;
    const  file  = this.props.file;      // Pass your PDF file URL or blob here
    return (
      <div style={{ width: '90%', margin:'1rem', overflowX: 'auto', display: 'flex', justifyContent: 'center' }}>
        <Document file={file} onLoadSuccess={this.onDocumentLoadSuccess}>
          {Array.from(new Array(numPages), (el, index) => (
            <Page 
              key={`page_${index + 1}`} 
              pageNumber={index + 1} 
              width={window.innerWidth * 1}   // Adjust to fit mobile screen
              renderTextLayer={false}         // Disable text layer overlay
              renderAnnotationLayer={false}   
              renderMode="svg"
            />
          ))}
        </Document>
      </div>
    );
  }
}

export default PDFViewer;
