import React           from 'react'; 
//import Container from 'react-bootstrap/Container';
import styles          from './what_is_BSB.module.css';
import Newspaper       from '../components/Newspaper.jsx';
import {Context}       from '../services/context.js'
import Paper           from '../components/Paper.jsx';
import { Helmet }           from 'react-helmet';


const text_1 = [
  {header:'Back-Step Blockchain explained'},
  <p>
    Back-Step Blockchain is built on Georg Krause’s pioneering invention from 2018, developed just before the Covid-19 pandemic brought many activities to a halt. 
    By fundamentally rethinking how blocks are processed, this technology delivers significant advantages to blockchain applications. 
    As blockchains underpin everything from cryptocurrencies to various decentralized systems, Back-Step Blockchain dramatically enhances performance and 
    open the door to thousands of applications that traditional blockchains can’t support. 
    Unlike conventional chains—which are often expensive, slow, and scatter your data across the globe—our approach offers improved efficiency and scalability.
  </p>,
  <p>
    The innovative cryptographic concept enables operations at speeds of up to millions of blocks per second—processing genuine blocks, not just transactions. 
    This breakthrough technology can even be implemented on a single chip, such as one found in a passport, 
    and requires very little power per block—a challenge for large mining farms. 
    Operating autonomously without a network of nodes, Back-Step Blockchain lays the foundation for a new generation of applications. 
    Moreover, it can host blocks from other blockchains within its payload, similar to virtual machines in computing. 
    Think of it as a parent/child relationship: the parent chain ensures immutability, while the child chains use a simple, fast hash. 
    With a hardware-based core that offers security far beyond software-only solutions, Back-Step Blockchain is engineered for resilience. 
    We recommend installing 3 to 5 systems in different locations to safeguard against hardware failures or disasters like fires or earthquakes, 
    and regular backups of the chain file will ensure your data are never lost.
  </p>,
  
  {header: 'How long should the blocks be?'},
  <p>
    Hopefully long enough, as the Back-Step blockchain can store variable length blocks from 16 byte to 100 MB. All the data are on the chain itself, no side-car files. 
    Each block can have a different size.
    Perfect for storing large documents, programm code, photos, videos, AI prompt and NFTs. 
  </p>,

  {header: 'Do we need networks?'},
  <p> Yes and No. In an application where a Back-Step Blockchain is integrated in a ID card or an passport only an application interface is needed, often wireless. 
      For server based applications the Back-Step Blockchain should be implemented in a redundancy mode with 3 or 5 connected units. 
      Here a high speed connection is preferrable. There will be application where the customer wants chains per country, region or even city. 
      Of course, if the block input is provided from a local network users or via internet 
      a network connection is required. But there is absolutely no need of huge clusters with thousands of nodes as known from traditional blockchains.   
  </p> ];

  
    const text_2 = [
      {header: 'Your First Back-Step Blockchain: Trust.it'},
      <p>
        You are invited to use Trust.it—a Back-Step blockchain application available at www.trust-it.app. 
        Trust.it is currently a desktop-only web app designed for private, commercial, and public use. 
        A social media version for mobile devices will follow soon. 
        <br /><br />
        Using Trust.it is free; you only pay a very small fee when you store blocks. Users can also encrypt their blocks, although encryption may not be available in all countries.
        You can add your own blocks to the chain, and they will remain there forever with no recurring payments. 
        There’s no need for any cryptocurrency—no Ether, no Satoshi, no bank cheques, and no wallet required. 
        The system deducts a Fee only for what you actually use, without any monthly or yearly subscriptions.
        Even better, users receive a credit of 300 Fee upon registration. 
      </p>,
      <p>
        Each block can store between 16 bytes and 10 megabytes of data. Trust.it currently uses English text as its payload, with support for additional languages coming soon.
        Any text longer than 1,024 characters (or bytes) can be uploaded as a file. We refer to such content as “large data,” which can include any 
        type of byte code without restrictions.
      </p>,
      <p>
        Please note that the chain is in its beta version, and there is a small risk of failure. We may occasionally halt the chain for brief periods to implement new features.
        We back up the chain daily—or after every 10,000 new blocks automatically—and you can also download the chain to your own computer.
        The Back-Step Blockchain powering Trust.it was initially designed with 100 TB of storage capacity, and we will continuously enhance its functionality and capacity.
      </p>,
      <p>
        The chain starts on a robust server system and will soon be extended to a 3-out-of-5 redundancy configuration during the initial test phase.
        All data you store on the chain, including during the test phase, will remain accessible on the chain for a planned duration of minimal 100 years—
        nothing can be erased from the chain.
        If you have sensitive data, you can store it encrypted on the chain, provided that encryption is permitted in your jurisdiction.
      </p>,
      <p>
        Users must register to place blocks on the chain, although their identities remain anonymous. Thanks to its low power consumption and high speed, 
        we are able to keep the chain at <b>cost very low</b>.
        We currently charge 2 Fee (with a capital F) per block—equivalent to 2 US cents or 1.4 Chinese jiao. 
        Everyone, including non-registered users, can read the chain free of charge.
        There is also a privacy mode in which all data are strongly encrypted; more details about the encryption are provided at "How it works".
        <br /><br />
        <b>Trust.it</b> is designed for the public, private and commercial organizations, but it is currently <b>not a digital currency.</b> 
        Instead, it serves as your personal <b>electronic vault</b>, <b>event logbook</b>, <b>supply chain verifier</b>, <b>black box recorder</b>, 
        <b>exchange platform</b> for all kinds of things—and much more.
        If you plan to install your own Back-Step Blockchain, Trust.it is an ideal test case and can give you many ideas.
      </p>
    ];
    
   
    
    export class What_is_BSB extends React.Component  
    { 

        //------------------------------------------------------------------
        //  Constructor
        //------------------------------------------------------------------
        constructor(props) 
        {
          super(props);
          this.component= "";
        }  
        
        componentDidMount()
        {
          //console.log('Component did mount in About Us');
          this.forceUpdate()
          window.scrollTo({top:0, left: 0, behavious: 'smooth'})
        }
       
        // className={styles.backdrop}
        //-------------------------------------------------------------------------
        //  For Portrait
        //-------------------------------------------------------------------------   
        render()
        { 
          if (Context.portrait)
          {
            this.component = 
            <div className={styles.portrait_backdrop}>

                <Helmet>
                <title>What is Back-Step Blockchain</title>
                <meta name="description" content="Explains what this new invention is all about and their hge advantages." />
                <meta charset="utf-8" />
                </Helmet>

                <div className={styles.grid_header_portrait}>
                  Back-Step Blockchain is...
                </div>

                <div className={styles.grid_item_portrait}> 
                < Paper textLines = {text_1}/> 
                </div>
  
                <div className={styles.grid_item_portrait}>
                  <Paper  textLines = {text_2}/>
                </div>
            </div>
          }    
          else
          //-------------------------------------------------------------------------
          //  For Lanscape
          //-------------------------------------------------------------------------   
          {
            this.component = 
              <div className={styles.backdrop}>

                <Helmet>
                  <title>What is Back-Step Blockchain</title>
                  <meta name="description" content="Explains what this new invention is all about and their hge advantages." />
                <meta charset="utf-8" />
                </Helmet>

      
                  <div className={styles.grid_container}>
     
                    <div className={styles.grid_item}> 
                    < Newspaper textLines = {text_1}/> 
                    </div>
      
                    <div className={styles.grid_item}>
                      <Newspaper  textLines = {text_2}/>
                    </div>
        
                  </div>
            </div>
          }
          return this.component ;
        }
    }
    
    
    