import React        from 'react'; 
import styles       from'./money.module.css';
import Newspaper    from '../components/Newspaper.jsx';
import Paper        from '../components/Paper.jsx';
import pic_museum   from '../pictures/Bank notes in Museum.webp';
import {Context}    from '../services/context.js';
import { Helmet }   from 'react-helmet';


const text_1  = [<h6>The Challenges of Electronic Currencies</h6>,
<p>
Despite the proliferation of digital currencies online, many are not true currencies but rather products governed by supply and demand. This dynamic fosters 
the illusion of easy wealth, spurred on by limited initial supply and aggressive marketing. Such strategies can indeed generate temporary success and 
satisfaction among early investors, but they also introduce a cycle of addiction, as the desire for more becomes insatiable. This is fundamentally unsustainable. 
Additionally, the extreme volatility of many cryptocurrencies renders them impractical for everyday transactions. For instance, the idea of buying everyday items 
like a baguette or a croissant with Bitcoin is fraught with difficulties, leading most merchants to avoid cryptocurrencies for daily transactions. 
However, integrating such digital currencies into the Back-Step Blockchain could offer a solution. We explore in another article how a cryptocurrency 
designed on the Back-Step Blockchain could address these issues, potentially transforming it into a beneficial tool for daily life.
</p>];

const text_3  = [<h6>No Crime Support Through Anonymity</h6>,
<p>
The anonymity offered by many cryptocurrencies has unfortunately made them a preferred tool for illicit activities. While this issue is widely recognized, 
only a handful of jurisdictions, like China, have taken decisive action against it. Unlike cryptocurrencies, fiat currencies are not anonymous; 
each banknote carries a serial number that can be traced, albeit with some effort, using modern AI technologies. 
Furthermore, fiat money's utility is generally confined to its country of origin, complicating its use in cross-border crimes. 
These factors contribute to the criminal preference for cryptocurrencies. It is imperative that steps are taken to curtail these abuses.
</p>];



const text_4  = [<h6>A New Approach to Electronic Money</h6>,
<p>
The Back-Step Blockchain introduces groundbreaking possibilities for cryptocurrencies. Here, we outline ideas and considerations for designing an electronic 
currency leveraging our advanced Back-Step Blockchain technology. We invite nations, experts, and forward-thinking designers to collaborate and share insights 
on this innovative venture. Traditional blockchains are hampered by slow transaction speeds, preventing the real-time processing of payments. 
A successful cryptocurrency must offer tangible benefits to all users—excluding those with illicit motives.
</p>,

<p>
Primarily, it should cater to everyday transactions, making purchasing as simple and quicker than using traditional credit cards. For merchants, the transaction 
fees associated with credit cards are replaced by significantly lower Back-Step Blockchain fees, leading to savings in both money and time. Users benefit 
from the ability to manage multiple currencies on a single chain, simplifying daily financial management without the need for physical currency exchanges or 
ATM visits. An average user’s annual transactions would require just 40 megabytes of storage, with all necessary computations performed on their smartphone.
</p>,

<p>
<b>Trust & Transparency</b>
</p>,

<p>
Each unit of currency is uniquely numbered and authenticated by the issuing authority, ensuring users can only spend what they possess and have not already spent. 
Manipulation of the chain is not only technically thwarted but would also result in the user being blocked from further transactions. The Back-Step 
Blockchain's design includes partially public readability, allowing authorities to monitor for and prevent money laundering, thus serving the public interest. 
User identities are securely stored, and anti-theft measures ensure that stolen funds cannot be illicitly used. Additionally, the system prioritizes 
the spending of older currency units first, streamlining transactions and minimizing the need for change. Government authorized services facilitate the 
merging or splitting of currency units, obviating the need to replace worn or damaged notes. This system allows for incremental implementation, with scalability 
following proven success, paving the way for a future free from the burden of minting physical currency.
</p>]

export class Money extends React.Component  
{

    //------------------------------------------------------------------
    //  Constructor
    //------------------------------------------------------------------
    constructor(props) 
    {
        super(props);
    }

    //------------------------------------------------
    //  Component Mount
    //------------------------------------------------
    componentDidMount()
    {

        this.forceUpdate();
        window.scrollTo({top:0, left: 0, behavious: 'smooth'});

    }



    //--------------------------------------------------------------------------
    // Prepare for rendering
    //--------------------------------------------------------------------------
    render()
    { 
      if (Context.portrait)
      {
            this.component = 
               <div className={styles.backdrop.portrait}>
   
                    <Helmet>
                     <title>Money Apps with Back-Step Blockchain</title>
                      <meta name="description" content="Explains new opportunities for financial applications and better Crypto money." />
                      <meta charset="utf-8" />
                    </Helmet>

  
                <div className={styles.grid_header_portrait}>
                Money turns the world arround
                </div>

                <div className={styles.grid_item_portrait}> 
                 < Paper textLines = {text_1}/> 
                </div>

                 
                <div className={styles.grid_item_portrait}>
                  <Paper  textLines = {text_3}/>
                </div>
  
                <div className={styles.grid_item_portrait}>
                  <Paper  textLines = {text_4}/>
                </div>

                <div className={styles.grid_image_portrait}>
                    <img src={pic_museum} width='250' alt="pic_museum" />
                  <p className={styles.text}> Parents show their kids how their grandparents paid for goods.</p>
                </div>
  
              </div>
      }
      else
      {
        this.component = 
           <div className={styles.backdrop}>
                <Helmet>
                  <title>Money Apps with Back-Step Blockchain</title>
                  <meta name="description" content="Explains new opportunities for financial applications and better Crypto money." />
                  <meta charset="utf-8" />
                </Helmet>
  
              <div className={styles.grid_container}>
  
                <div className={styles.grid_item_left_row_1}> 
                 < Newspaper textLines = {text_1}/> 
                </div>
  
                <div className={styles.grid_item_left_row_2}>
                 <Newspaper  textLines = {text_3}/>
                </div>
  
                <div className={styles.grid_item_left_row_3}>
                  <Newspaper  textLines = {text_4}/>
                </div>


                <div className={styles.grid_item_right}>
                  <p> </p>
                  <img src={pic_museum} width='250' alt="pic_museum" />
                  <p className={styles.text}>Parents show their kids how their grandparents paid for goods.</p>

                </div>


              </div>
        </div>
      }
      return this.component;
    }

}