import React, { useState }  from "react";
import {Card}               from "react-bootstrap";
import { Button }           from "react-bootstrap";
import CustomButton         from './custom_button.jsx';
import '../App.css';

//--------------------------------------------------------
//  Component definition'
//
//--------------------------------------------------------
export default function MyCard(props) 
{

  const text = props.text;
  let component;
  let maxHeight = 500;
 
 const [isReadMore, setIsReadMore] = useState(true);  
 const [pic, setPic] = useState(
    {
      small_size: true,
      width:   '100%'
    } );
    

  
  //---------------------------------------------
  //  We need a callback to the caller of the card
  //---------------------------------------------

  
  //-------------------------------------------------
  //  prepare what to render
  //-------------------------------------------------

    component =
    <Card style={{ color:'white', boxShadow: '5px 5px 5px black', fontSize: '0.6rem', width: '30vw', backgroundColor: "#B09070", textAlign: "justify", padding:8, margin: 20, height: "maxHeight" }}>
        <Card.Header style={{color:'black', fontSize: '1rem', fontWeight:'bold' }}>{props.title}</Card.Header>
        <Card.Img src={props.img} variant="top" alt="Card" width={pic.width}  onClick={props.detail} />
        <Card.Body >
            <Card.Text >{ text}  </Card.Text>
          </Card.Body>
        <Card.Footer >
         <CustomButton type="button" enabled={true} style={{margin: '1rem'}} onClick={props.detail}> See more...</CustomButton>
        </Card.Footer>
    </Card>
     
    return component
}